import { useContext } from 'react';

import ContentMain from '../components/ContentMain';

import '../css/Caricature.css';

import { content } from '../data/data.js';
import caricature from '../images/caricature.svg';

import { ModalContext } from '../services/ModalContext';

export default function About(){
    const {
        openModal,
    } = useContext(ModalContext);
 
    return(
        <ContentMain content={content.about}>
            <img 
                src={ caricature }
                id='caricature'
                alt=''
                onClick={() => openModal({content: caricature, type: "image"})}
            />
        </ContentMain>
    );
};
