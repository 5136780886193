import '../css/Thought.css';

export default function Thought(props){
    const {
        id,
        title,
        image,
        body,
        onClick,
    } = props;

    return(
        <div
            className='thought' 
            key={id}
            onClick={onClick}
        >
            <div
                className='thought-title' 
            >
                {title}
            </div>
            <div
                className='thought-image-container' 
            >
                <img
                    src={image}
                    alt=''
                    height={'80%'}
                />
            </div>
            <div
                className='thought-body' 
            >
                {body}
            </div>
        </div>
    );
};
