import React, { useEffect, useState, useContext } from 'react';

import axios from 'axios';
import parseMessageForCode from '../services/parseMessageForCode';
import hljs from 'highlight.js';

import { UserAuthenticationContext } from '../services/UserAuthenticationContext';
import DashboardMenu from './DashboardMenu';
import Button from './Button';

import config from '../config.js';

import '../css/dracula.css';
import '../css/ChatManager.css';

export default function ChatManager(){
    const {
        getAuthHeader,
        handleLogout,
    } = useContext(UserAuthenticationContext);

    const [chatLogs, setChatLogs] = useState([]);

    const sanitizeChatLogs = (logs) => {
        const sanitizedLogs = logs.map((log) => {
            const message = (log.message !== undefined) ? log.message : '';
            return {...log, message};
        });
        return(sanitizedLogs);
    }

    const fetchChatLogs = async () => {
        try{
            const response = await axios.get(config.API_GETCHATS, getAuthHeader());
            const sanitizedLogs = sanitizeChatLogs(response.data);
            setChatLogs(splitChats(sanitizedLogs));
        }catch(err){
            handleLogout();
        }
    };

    const splitChats = (chatLogs) => {
        //Accumulator, CurrentValue
        const splitLogs = chatLogs.reduce((acc, cur) => {
            if (!acc.length || acc[acc.length - 1][0].id !== cur.id) {
                acc.push([cur]);
            } else {
                acc[acc.length - 1].push(cur);
            }

            return acc;
        }, []);

        return splitLogs;
    };

    const handleDeleteChat = async (id) => {
        await axios.delete(`${config.API_DELETECHAT}${id}`, getAuthHeader());
        fetchChatLogs();
    }

    useEffect(() => {
        fetchChatLogs();
    }, []);

    useEffect(() => {
        document.querySelectorAll('#unhighlighted-code').forEach(element => {
            hljs.highlightElement(element);
            element.id = 'highlighted-code';
        });
    }, [chatLogs]);

    return (
        <>
            <DashboardMenu />
            {chatLogs.map((chatArray, chatArrayIndex) => (
                <div
                    className='chatmanager-container'
                    key={chatArrayIndex}
                >
                    {chatArray.map((messageObject, messageObjectIndex) => (
                        <div
                            className='chatmanager-message-container' 
                            key={messageObjectIndex}
                        >
                            <div
                                className='chatmanager-message-text-container' 
                                style={{
                                    color: (messageObject.userId === "assistant" ? 'black' : 'green'),
                                }}
                            >
                                {parseMessageForCode(messageObject.message)}
                           </div>
                        </div>
                    ))}
                    <div
                        className='chatmanager-delete-button-container'
                        key={chatArrayIndex}
                    >
                        <Button 
                            label="Delete Chat"
                            onClick={() => handleDeleteChat(chatArray[0].id)}
                            className='chatmanager-delete-button'
                        />
                    </div>
                </div>
            ))}
        </>
    );
};
