import { useContext } from 'react';
import { UserAuthenticationContext } from '../services/UserAuthenticationContext';

import Button from './Button';

import '../css/LogoutButton.css';

export default function Logout() {
    const {
        handleLogout,
    } = useContext(UserAuthenticationContext);

    return (
        <Button
            label="Logout"
            onClick={() => handleLogout()}
            className='logout-button'
        />
    );
}
