import React, { useEffect, useState, useContext } from 'react';

import axios from 'axios';

import { UserAuthenticationContext } from '../services/UserAuthenticationContext';

import DashboardMenu from './DashboardMenu';
import PromptCreateForm from './PromptCreateForm';
import Prompt from './Prompt';

import config from '../config.js';

import '../css/Prompts.css';

export const Prompts = () => {
    const {
        getAuthHeader,
        handleLogout,
    } = useContext(UserAuthenticationContext);

    const [promptsArray, setPromptsArray] = useState([]);

    const fetchPrompts = async () => {
        try{
            const response = await axios.get(config.API_GETPROMPTS, getAuthHeader());

            setPromptsArray(response.data);
        }catch(err){
            handleLogout();
        }
    };

    useEffect(() => {
        fetchPrompts();
    }, []);

    return (
        <div
            className='prompts-container' 
        >
            <DashboardMenu />
            <PromptCreateForm fetchPrompts={fetchPrompts} />
            <div
                className='display-prompts-container' 
            >
                {promptsArray.map(obj => (
                    <Prompt promptData={obj} fetchPrompts={fetchPrompts} key={obj._id} />
                ))}
            </div>
        </div>
    );
};

export default Prompts;
