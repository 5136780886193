import React, { useState, useEffect, useContext } from 'react';

import axios from 'axios';

import { UserAuthenticationContext } from '../services/UserAuthenticationContext';

import Button from './Button';

import '../css/ChatHistoryMessage.css';

import config from '../config.js';

export default function ChatHistoryMessage({id, setUuid, handleDeleteChat}){
    const {
        getAuthHeader,
    } = useContext(UserAuthenticationContext);

    const [message, setMessage] = useState("");
    const chatHistoryCharLength = 80;

    const fetchOneChatMessage = async () => {
        try{
            const response = await axios.get(`${config.API_FINDONECHATBYID}${id}`, getAuthHeader());

            if(response.data.message.length > chatHistoryCharLength){
                const trimmedResponse = response.data.message.substring(0, chatHistoryCharLength) + "...";
                setMessage(trimmedResponse);
            }else{
                setMessage(response.data.message);
            }
        }catch(err){
            console.log(err);
        }
    };

    useEffect(() => {
        fetchOneChatMessage();
    }, []);

    return(
        <div
            className='chat-history-message-container'
            key={id} 
        >
            <div
                className='chat-history-message'
                key={id} 
                onClick={() => setUuid(id)}
            >
                {message}
            </div>
            <Button 
                label="Delete"
                onClick={() => handleDeleteChat(id)}
                className='chat-history-message-delete-button'
            />
        </div>
    )
}
