import axios from 'axios';

const config = require('../config.js');

const getImgNameUrl = async (imgName) => {
     try{
        const response = await axios.get(`${config.API_GETIMAGEURL}${imgName}`);
        return(response.data);
    }catch(err){
        console.log(err);
        return("");
    }
}

export const getBlogsService = async () => {
    try{
        const response = await axios.get(config.API_GETBLOGS);
        const tempBlogs = response.data;

        const blogs = await Promise.all(tempBlogs.map(async (blog) => {
            const imgData = await getImgNameUrl(blog.imgName);
            
            return { ...blog, imgSrc: imgData.url };
        }));

        return(blogs);
   }catch(err){
        console.log(err);
        return([])
    }
}
