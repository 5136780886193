import React, { useState, useContext } from 'react';

import axios from 'axios';

import { UserAuthenticationContext } from '../services/UserAuthenticationContext';

import config from '../config.js';

import '../css/PromptCreateForm.css';

export default function PromptCreateForm({fetchPrompts}){
    const {
        getAuthHeader,
    } = useContext(UserAuthenticationContext);

    const [promptName, setPromptName] = useState("");
    const [promptText, setPromptText] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const handleCreatePrompt = async (event) => {
        event.preventDefault();
        setErrorMessage('');

        try{
            await axios.post(config.API_CREATEPROMPT, { 
                promptName: promptName,
                promptText: promptText,
            }, getAuthHeader());
            
            setPromptName("");
            setPromptText("");
            fetchPrompts();
        }catch(error){
            if(error.response && error.response.data){
                setErrorMessage(error.response.data.message);
            }else{
                setErrorMessage('An unknown error occurred.');
            }
        }
    }

    return(
        <form
            onSubmit={(event) => handleCreatePrompt(event)} 
            className='prompts-form'
        >
            <div>
                {errorMessage}
            </div>

            <input
                className='prompts-form-input-name'
                type='name'
                name='name'
                placeholder="Prompt Name"
                value={promptName}
                onChange={(event) => setPromptName(event.target.value)}
                required
            />

            <textarea
                className='prompts-form-input-textarea'
                type='text'
                name='text'
                placeholder="Prompt Text"
                value={promptText}
                onChange={(event) => setPromptText(event.target.value)}
                required
            />

            <input
                type='submit'
                value="Create Prompt"
                className='create-prompt-button'
            />
        </form>
    ) 
}
