import { useState, createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';

const config = require('../config.js');

export const UserAuthenticationContext = createContext();

export const UserAuthenticationProvider = ({children}) => {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleLogin = async (event) => {
        event.preventDefault();
        setErrorMessage('');
        console.log('Signing in');

        try{
            const response = await axios.post(config.API_LOGIN, { email, password });
            const { authToken } = response.data;

            //Cookies.set(config.TOKEN_NAME, authToken, { expires: 1, secure: true });
            Cookies.set(config.TOKEN_NAME, authToken, { expires: 1 });

            navigate('/dashboard');
        }catch(error){
            if(error.response && error.response.data){
                setErrorMessage(error.response.data.message);
            }else{
                setErrorMessage('An unknown error occurred.');
            }
        }
    }

    const getAuthHeader = () => {
        const authToken = Cookies.get(config.TOKEN_NAME);

        return({
            headers: {
                'Authorization': `Bearer ${authToken}`
            }
        })
    }

    const isLoggedIn = async () => {
        try {
            const response = await axios.get(config.API_AUTHENTICATED, getAuthHeader());

            if(response.status === 200){
                return(true);
            }
        }catch(err){
            return(false);
        }

        return(false);
    }

    const checkToken = async () => {
        const checkIsLoggedIn = await isLoggedIn();

        if(!checkIsLoggedIn){
            console.log("Authentication Failed");
            navigate('/');
        }

        return(true);
    }

    const handleLogout = async () => {
        await axios.get(config.API_LOGOUT, getAuthHeader());

        Cookies.remove(config.TOKEN_NAME);
        setEmail('');
        setPassword('');
        setErrorMessage('');

        console.log('Successfully logged out');
        navigate('/');
    }

    return(
        <UserAuthenticationContext.Provider
            value={{
                getAuthHeader,
                handleLogin,
                handleLogout,
                email,
                setEmail,
                password,
                setPassword,
                errorMessage,
                checkToken,
                isLoggedIn,
            }}
        >
            {children}
        </UserAuthenticationContext.Provider>
    );
}
