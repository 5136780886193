import gitlogo from "../images/logos/gitlogo.svg";
import javascriptlogo from "../images/logos/javascriptlogo.svg";
import mongodblogo from "../images/logos/mongodblogo.svg";
import nodejslogo from "../images/logos/nodejslogo.svg";
import reactlogo from "../images/logos/reactlogo.svg";
import vimlogo from "../images/logos/vimlogo.svg";
import vuejslogo from "../images/logos/vuejslogo.svg";
import typescriptlogo from "../images/logos/typescriptlogo.svg";
import rustlogo from "../images/logos/rustlogo.svg";
import cpluspluslogo from "../images/logos/cpluspluslogo.svg";
import pythonlogo from "../images/logos/pythonlogo.svg";
import csslogo from "../images/logos/csslogo.svg";

import willai_crying from '../images/willai_avatars/willai_crying.svg';
import willai_disappointed from '../images/willai_avatars/willai_disappointed.svg';
import willai_hearteyes from '../images/willai_avatars/willai_hearteyes.svg';
import willai_laughingtears from '../images/willai_avatars/willai_laughingtears.svg';
import willai_lookdown from '../images/willai_avatars/willai_lookdown.svg';
import willai_lookleft from '../images/willai_avatars/willai_lookleft.svg';
import willai_lookright from '../images/willai_avatars/willai_lookright.svg';
import willai_lookup from '../images/willai_avatars/willai_lookup.svg';
import willai_neutral from '../images/willai_avatars/willai_neutral.svg';
import willai_sad from '../images/willai_avatars/willai_sad.svg';
import willai_scared from '../images/willai_avatars/willai_scared.svg';
import willai_sleepy from '../images/willai_avatars/willai_sleepy.svg';
import willai_smilewithtongue from '../images/willai_avatars/willai_smilewithtongue.svg';
import willai_smiling from '../images/willai_avatars/willai_smiling.svg';
import willai_smilingeyes from '../images/willai_avatars/willai_smilingeyes.svg';
import willai_sunglasses from '../images/willai_avatars/willai_sunglasses.svg';
import willai_talking from '../images/willai_avatars/willai_talking.svg';
import willai_thinking from '../images/willai_avatars/willai_thinking.svg';

export const sentimentOptions = {
    'crying': willai_crying,
    'disappointed': willai_disappointed,
    'hearteyes': willai_hearteyes,
    'laughingtears': willai_laughingtears,
    'lookdown': willai_lookdown,
    'lookleft': willai_lookleft,
    'lookright': willai_lookright,
    'lookup': willai_lookup,
    'nottalking': willai_neutral,
    'sad': willai_sad,
    'scared': willai_scared,
    'sleepy': willai_sleepy,
    'silly': willai_smilewithtongue,
    'smiling': willai_smiling,
    'smilingeyes': willai_smilingeyes,
    'sunglasses': willai_sunglasses,
    'talking': willai_talking,
    'thinking': willai_thinking,
};

export const logos = [
    gitlogo,
    javascriptlogo,
    mongodblogo,
    nodejslogo,
    reactlogo,
    vimlogo,
    vuejslogo,
    typescriptlogo,
    rustlogo,
    cpluspluslogo,
    pythonlogo,
    csslogo,
];

export const content = {
        about: {
            intro : "Hi, I'm Will March",
            title : "Full-Stack Web Developer & Graphic Designer",
            mainText : "I design and build websites and digital products that help people and brands reach their goals.",
            contentId : "about-content",
        },
        projects: {
            title : "Projects",
            mainText : "No matter the digital medium, the experience should be user-centric, visually pleasing, and easy to use.",
            contentId : "projects-content",
        },
        skills: {
            title : "Skills",
            mainText : "A list of technologies I use to build quality digital experiences.",
            contentId : "skills-content",
        },
        thoughts: {
            title : "Thoughts",
            mainText : "Sharing my ideas.",
            contentId : "thoughts-content",
        },
        contact: {
            title : "Contact",
            mainText : "Send me a message!",
            contentId : "contact-content",
        }
    };

export const projectsImages = [
        {
            image: require('../images/skymarket/0.webp'),
            text: 'Skymarket is a platform for instant grocery, food, and retail delivery',
        },
        {
            image: require('../images/skymarket/1.webp'),
            text: 'I was contracted to design, develop, and deploy an iOS and Android app for the company',
        },
        {
            image: require('../images/skymarket/2.webp'),
            text: 'I provided the company with a single codebase solution using trusted technologies',
        },
        {
            image: require('../images/skymarket/3.webp'),
            text: 'Delivering apps that are visually pleasing, user-friendly, fast, and responsive',
        },
        {
            image: require('../images/skymarket/4.webp'),
            text: 'Delivering apps that are visually pleasing, user-friendly, fast, and responsive',
            text: 'Skymarket is now able to reach more customers than ever before',
        },
    ];
