import React, { useState, useContext } from "react";

import axios from 'axios';

import { UserAuthenticationContext } from '../services/UserAuthenticationContext';

import config from '../config.js';

export default function BlogForm({getBlogs}){
    const {
        getAuthHeader,
    } = useContext(UserAuthenticationContext);

    const [blogTitle, setBlogTitle] = useState("");
    const [blogText, setBlogText] = useState("");
	const [caption, setCaption] = useState("");
    const [image, setImage] = useState(null);

    function handleImageChange(event){
        setImage(event.target.files[0]);
    }

    function handleCaptionChange(event){
        setCaption(event.target.value);
    }

    function handleBlogTitleChange(event){
        setBlogTitle(event.target.value);
    }

    function handleBlogTextChange(event){
        setBlogText(event.target.value);
    }

    const uploadImage = async () => {
        try{
            const formData = new FormData();
            formData.append("image", image);
            formData.append("caption", caption);

            const response = await axios.post(config.API_IMAGEUPLOAD, formData, getAuthHeader());
            return(response.data);
        }catch(err){
            console.log(err);
        }
    }

    const postBlog = async (imgName) => {
        try{
            const blogObject = {
                title: blogTitle,
                body: blogText,
                date: Date.now(),
                userId: "",
                imgName: imgName,
            }

            const response = await axios.post(config.API_CREATEBLOG, blogObject, getAuthHeader());
            return(response.data);
        }catch(err){
            console.log(err);
        }
    }

	const handleSubmit = async (event) => {
        event.preventDefault();

        const data = await uploadImage();

        if(data === undefined || data === null){
            console.log("Failed to upload image");
        }else{
            await postBlog(data.id);

            setBlogTitle("");
            setBlogText("");
            setImage(null);
            setCaption("");
            document.getElementById("image").value = "";
            getBlogs();
        }
	}

    return (
        <div>
            <form 
                onSubmit={handleSubmit}
                >
                <div>
                    <input
                        type="text"
                        id="blogTitle"
                        name="blogTitle"
                        placeholder={"Blog Title"}
                        value={blogTitle}
                        onChange={handleBlogTitleChange}
                        required
                    />
                </div>
                <div>
                    <textarea
                        type="text"
                        id="blogText"
                        name="blogText"
                        placeholder={"Blog Text"}
                        value={blogText}
                        onChange={handleBlogTextChange}
                        required
                    />
                </div>
                <div>
                    <input
                        type="text"
                        id="caption"
                        name="caption"
                        placeholder={"Image Caption"}
                        value={caption}
                        onChange={handleCaptionChange}
                        required
                    />
                </div>
                <div>
                    <input
                        type="file"
                        id="image"
                        name="image"
                        onChange={handleImageChange}
                        encType="multipart/form-data"
                        required
                    />
                </div>
                <button
                    type="submit"
                >
                    {"Submit"}
                </button>
            </form>
        </div>
    );
};
