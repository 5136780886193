import { useEffect, useState, useContext } from 'react';

import "../css/Slideshow2.css";

import { ModalContext } from '../services/ModalContext';

import AppleBadge from '../images/app_store_badges/apple-app-store-badge.svg';
import AndroidBadge from '../images/app_store_badges/google-play-badge.png';

const config = require('../config.js');

export default function Slideshow2(props) {
    const {
        imagesData,
        //slideshowSettings,
    } = props;

    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const { openModal } = useContext(ModalContext);

    const slideshowAutoAdvance = () => {
        setCurrentImageIndex(getNewImageIndex(1)); 
    };

    useEffect(() => {
        const slideshowInterval = setInterval(
            () => slideshowAutoAdvance(),
            8000
        );

        return() => clearInterval(slideshowInterval);
    });
    
    const getNewImageIndex = (delta) => {
        const newImageIndex = currentImageIndex + delta;

        if(newImageIndex >= imagesData.length){
            return(0);
        }else if(newImageIndex < 0){
            return(imagesData.length - 1);
        };
        
        return(newImageIndex);
    };

    const ImageContainer = () => {
        const { text, image } = imagesData[currentImageIndex];

        return(
            <div
                className='project-card-content-container' 
            >
               <div className='project-card-image-container'>
                    <img
                        src={image}
                        alt=''
                        onClick={() => openModal({content: image, type: "image"})}
                    />
                </div>
                <div className='project-card-text-container'>
                    {text}
                </div>
            </div>
        );
    };


    function handleOpenLink(link) {
        window.open(link, '_blank');
    };

    return(
        <div
            className='project-card-container' 
        >
            <div
                className='store-badges-container' 
            >
                <h1>
                {"Skymarket"}
                </h1>
                <img
                    src={AppleBadge}
                    alt=''
                    onClick={() => handleOpenLink(config.IOS_APP_LINK)}
                />
                <img
                    src={AndroidBadge}
                    alt=''
            /*onClick={() => handleOpenLink(config.ANDROID_APP_LINK)}*/
                />
            </div>
            <ImageContainer />
        </div>
    );
}
