import { useEffect, useState } from "react";

import  "../css/MenuMain.css";

import MenuItem from "./MenuItem";

export default function MenuMain() {
    const status = {active: "menu-item-active", inactive: "menu-item-inactive"};

    const [activeContent, setActiveContent] = useState('about-content');

    const setStatus = () => {
        setActiveContent(document.querySelector('.fadeIn-active')?.children[0].id);
    };

    useEffect(() => {
        setInterval(setStatus, 200);
    });

    const scrollToContent = (event) => {
        const title = event.currentTarget.lastChild.data;
        const anchor = document.querySelector('#' + title.toLowerCase() + '-content');
        anchor.scrollIntoView({behavior: 'smooth'});
    }

    return(
        <menu id="menu-main">
            <div className="menu-icon" />
            <div className="menu-icon" />
            <div className="menu-icon" />
            <MenuItem 
                title={"About"}
                onClick={event => scrollToContent(event)}
                status={
                    activeContent === 'about-content' ? 
                    status.active : 
                    status.inactive
                }
            />
            <MenuItem 
                title={"Projects"}
                onClick={event => scrollToContent(event)}
                status={
                    activeContent === 'projects-content' ? 
                    status.active : 
                    status.inactive
                }
            />
            <MenuItem
                title={"Skills"}
                onClick={event => scrollToContent(event)}
                status={
                    activeContent === 'skills-content' ? 
                    status.active : 
                    status.inactive
                }
            />
            <MenuItem
                title={"Thoughts"}
                onClick={event => scrollToContent(event)}
                status={
                    activeContent === 'thoughts-content' ? 
                    status.active : 
                    status.inactive
                }
            />
            <MenuItem
                title={"Contact"}
                onClick={event => scrollToContent(event)}
                status={
                    activeContent === 'contact-content' ? 
                    status.active : 
                    status.inactive
                }
            />
       </menu>
    );
}
