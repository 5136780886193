import Login from './Login';

import "../css/Footer.css";

export default function Header() {
    function getYear() {
        return "2023-" + new Date().getFullYear();
    }

    return(
        <footer className="footer-main">
            <div id="footer-content">
                {"Will March " + getYear()}
                <Login />
            </div>
       </footer>
    );
}
