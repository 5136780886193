import { useState, useEffect, useContext } from 'react';

import ContentMain from '../components/ContentMain';
import Thought from '../components/Thought';

import { content } from '../data/data.js';
import { ModalContext } from '../services/ModalContext';
import { getBlogsService } from '../services/getBlogsService';

import '../css/Thoughts.css';

export default function Thoughts(){
    const { openModal } = useContext(ModalContext);

    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        getBlogs()
    }, []);

    const getBlogs = async () => {
        try{
            const blogs = await getBlogsService();

            if(blogs !== undefined){
                setBlogs(blogs);
            }
       }catch(err){
            console.log(err);
        }
    }

    return(
        <ContentMain content={content.thoughts}>
            <div
                className='thoughts-container'
            >
                {blogs.map((blog, blogIndex) => (
                    <Thought 
                        id={blog._id}
                        key={blogIndex}
                        title={blog.title}
                        image={blog.imgSrc}
                        body={blog.body}
                        onClick={() => openModal({content: blog, type: "blogpost"})}
                    />
                ))}
            </div>
        </ContentMain>
    );
};
