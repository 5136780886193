module.exports = {
    ANDROID_APP_LINK: 'https://play.google.com/store/apps/details?id=io.skymarket.skymarket',
    IOS_APP_LINK: 'https://apps.apple.com/us/app/skymarket-io/id1670263169',
    TOKEN_NAME: 'auth_token',

    API_LOGIN: '/api/login',
    API_LOGOUT: '/api/logout',
    API_SIGNUP: '/api/signup',
    API_AUTHENTICATED: 'api/authenticated',

    API_CREATEBLOG: '/api/createBlog/',
    API_DELETEBLOG: '/api/deleteBlog/',
    API_GETBLOGS: '/api/getBlogs/',

    API_IMAGEUPLOAD: '/api/imageUpload/',
    API_DELETEIMAGE: '/api/deleteImage/',
    API_GETIMAGEURL: '/api/getImageUrl/',

    API_FINDCHATBYID: '/api/findChatById/',
    API_GETCHATIDS: '/api/getChatIds/',
    API_DELETECHAT: 'api/deleteChat/',
    API_FINDONECHATBYID: '/api/findOneChatById/',
    API_GETCHATS: '/api/getChats',
    API_SAVECHAT: '/api/savechat',

    API_DELETEPROMPT: 'api/deletePrompt/',
    API_UPDATEPROMPT: '/api/updatePrompt/',
    API_CREATEPROMPT: '/api/createPrompt/',
    API_GETPROMPTS: '/api/getPrompts',

    API_DELETEUSER: 'api/deleteUser/',
    API_UPDATEUSER: '/api/updateUser/',
    API_GETUSERS: '/api/getUsers',
};
