import ContentMain from '../components/ContentMain';
//import Slideshow from '../components/Slideshow';
import Slideshow2 from '../components/Slideshow2';

import { content, projectsImages } from '../data/data.js';

export default function Projects(){
    return(
        <ContentMain content={content.projects}>
            <Slideshow2
                imagesData={projectsImages}
                slideshowSettings={{
                    fadeInSpeed: '2s',
                    pauseLength: 10000,
                    intervalLength: 5000,
                }}
            />
            {/*
            <Slideshow
                imagesData={projectsImages}
                slideshowSettings={{
                    fadeInSpeed: '2s',
                    pauseLength: 10000,
                    intervalLength: 5000,
                }}
                slideshowImageStyle={{
                    border: '8px solid black',
                    borderStyle: 'outset',
                }}
                slideshowThumbnailImageStyle={{
                    border: '3px solid black',
                    borderStyle: 'outset',
                }}
            />
            */}
        </ContentMain>
    );
};
