import { useState, useEffect, useContext } from "react";

import { v4 as uuidv4 } from 'uuid';

import willai_talking from '../images/willai_avatars/willai_talking.svg';

import { UserAuthenticationContext } from '../services/UserAuthenticationContext';

import Chat from '../components/Chat';
import ChatHistory from '../components/ChatHistory';
import ChatPromptDropdown from '../components/ChatPromptDropdown';
import Button from './Button';

import '../css/FloatingChatBox.css';

const inituuid = uuidv4();

function FloatingChatBox() {
    const {
        isLoggedIn,
    } = useContext(UserAuthenticationContext);

    const [showHistory, setShowHistory] = useState(false);
    const [uuid, setUuid] = useState(inituuid);
    const [isChatOpen, setIsChatOpen] = useState(false);
	const [selectedPrompt, setSelectedPrompt] = useState("WillAIPrompt");

    useEffect(() => {
        async function checkLoggedInStatus(){
            const loggedIn = await isLoggedIn();

            if(loggedIn){
                setShowHistory(true);
            }else{
                setShowHistory(false);
            }
        }

        checkLoggedInStatus();
    }, []);

    const toggleChat = () => {
        setIsChatOpen(!isChatOpen);
    };

    const createNewChat = () => {
        setUuid(uuidv4());
    }

    return (
        <div className='floatingchatbox-container'>
           {!isChatOpen && <div 
                className='floatingchatbox-willai-image-container'
                onClick={toggleChat} 
            >
                <div className='floatingchatbox-willai-text-container'>
                    {"Chat with WillAI"}
                </div>
                <img src={willai_talking} alt=''/>
            </div>}
            {isChatOpen && <div
                className='floatingchatbox-chatbox-container' 
            >
                {showHistory && <div
                    className='floatingchatbox-chatbox-history-container'
                >
                    <Button
                        className='new-chat-button'
                        label={"+ New Chat"}
                        onClick={createNewChat}
                    />
                    <ChatHistory
                        uuid={uuid}
                        setUuid={setUuid}
                        createNewChat={createNewChat}
                    />
                </div>}
                <div
                    className=''
                >
                    <div
                        className='floatingchatbox-button-container'
                    >
                       <Button 
                            className='floatingchatbox-close-button'
                            label={"Close"}
                            onClick={toggleChat}
                        />
                        {showHistory && <ChatPromptDropdown
                            selectedPrompt={selectedPrompt}
                            setSelectedPrompt={setSelectedPrompt}
                        />}
                    </div>
                    <Chat
                        uuid={uuid}
                        selectedPrompt={selectedPrompt}
                    />
                </div>
            </div>}
        </div>
    );
}

export default FloatingChatBox;
