import "../css/Header.css";
import Logo from "./Logo";

export default function Header() {
    return(
        <header className="header-main">
            <div id="header-content">
                <Logo />
            </div>
       </header>
    );
}