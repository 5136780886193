import "../css/Logo.css";
import wmLogo from '../images/logos/willmarchlogo.svg';

export default function Logo() {
    return(
        <>
            <div className="logo">
                <img src={ wmLogo } alt="Site Logo" />
            </div>
            <div className="logo-text">
                <div className="logo-text-blue">
                    WILL
                </div>
                <div className="logo-text-gray">
                    MARCH
                </div>
            </div>
        </>
    );
}
