import "../css/TechLogos.css";

import { logos } from '../data/data.js';

export default function TechLogos(){
    return(
        <div className='tech-logos-container'>
            {logos.map((logo, key) =>
                <div
                    className='tech-logos-content' 
                    key={key}
                >
                    <img src={ logo } alt="" key={key} />
                </div>
            )}
        </div>
    );
}
