import React, { useState, useEffect, useContext } from 'react';

import axios from 'axios';

import { UserAuthenticationContext } from '../services/UserAuthenticationContext';

import ChatHistoryMessage from './ChatHistoryMessage';

import '../css/ChatHistory.css';

import config from '../config.js';

export default function ChatHistory({uuid, setUuid, createNewChat}){
    const {
        getAuthHeader,
    } = useContext(UserAuthenticationContext);

    const [ids, setIds] = useState([]);

    const fetchChatIds = async () => {
        try{
            const response = await axios.get(config.API_GETCHATIDS, getAuthHeader());
            //reverse the array so the most recent chats are at the top
            setIds(response.data.reverse());
        }catch(err){
            console.log(err);
        }
    };

    const handleDeleteChat = async (id) => {
        await axios.delete(`${config.API_DELETECHAT}${id}`, getAuthHeader());
     
        if(id === uuid){
            createNewChat();
        }

        fetchChatIds();
    }

    useEffect(() => {
        fetchChatIds();
    }, [uuid]);

    return(
        <div
            className='chat-history-container'
        >
            <div
                className='chat-history-container-scroll'
            >
                {ids.map((id, idIndex) => (
                   <ChatHistoryMessage
                        id={id}
                        setUuid={setUuid}
                        handleDeleteChat={handleDeleteChat}
                    />
                ))}
            </div>
        </div>
    )
}
