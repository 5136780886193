import React, { useState, useContext } from 'react';

import axios from 'axios';

import { UserAuthenticationContext } from '../services/UserAuthenticationContext';

import Button from './Button';

import config from '../config.js';

import '../css/Prompt.css';

export default function UserCell({userData, fetchUsers}){
    const {
        getAuthHeader,
    } = useContext(UserAuthenticationContext);

    const id = userData._id;
    const [name, setName] = useState(userData.name);
    const [email, setEmail] = useState(userData.email);
    const [role, setRole] = useState(userData.role);

    const [isEditable, setIsEditable] = useState(false);

    const onEditClick = () => {
        setIsEditable(true);
    }

    const onCancelClick = () => {
        setIsEditable(false);
    }

    const handleDeleteUser = async () => {
        await axios.delete(`${config.API_DELETEUSER}${id}`, getAuthHeader());

        fetchUsers();
    }

    const handleUpdateUser = async () => {
        await axios.put(`${config.API_UPDATEUSER}${id}`, {
            name: name,
            email: email,
            role: role,
        }, getAuthHeader());
        
        setIsEditable(false);
    }

    function NotEditableContent(){
        return(
            <>
                <td>
                    {name}
                </td>
                <td>
                    {email}
                </td>
                <td>
                    {role}
                </td>
                <td
                    className='user-cell-edit-button-container'
                >
                   <Button
                        label={"Edit"}
                        onClick={onEditClick}
                        className='user-cell-edit-button'
                    />
               </td>
            </>
        );
    }

    function EditableContent(){
        return(
            <>
                <td>
                    <input
                        value={name}
                        name='name'
                        type='text'
                        onChange={(event) => setName(event.target.value)}
                        className='user-cell-textarea'
                        required
                    />
                </td>
                <td>
                    <input
                        value={email}
                        name='email'
                        type='email'
                        onChange={(event) => setEmail(event.target.value)}
                        className='user-cell-textarea'
                        required
                    />
                </td>
                <td>
                    <input
                        value={role}
                        name='role'
                        type='text'
                        onChange={(event) => setRole(event.target.value)}
                        className='user-cell-textarea'
                        required
                    />
                </td>
                <td
                    className='user-cell-cancel-button-container'
                >
                    <Button
                        label={"Save"}
                        onClick={handleUpdateUser}
                        className='user-cell-save-button'
                    />
                    <Button
                        label={"Cancel"}
                        onClick={onCancelClick}
                        className='user-cell-cancel-button'
                    />
                    <Button
                        label={"Delete"}
                        onClick={handleDeleteUser}
                        className='user-cell-delete-button'
                    />
                </td>
            </>
        )
    }

    return(
        <tr key={userData._id}>
            {isEditable && EditableContent()}
            {!isEditable && NotEditableContent()}
        </tr>
    )
};
