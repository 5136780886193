import { React } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { ModalProvider } from './services/ModalContext';

import Modal from './components/Modal';

import Header from './components/Header';
import MenuMain from './components/MenuMain';

import About from './components/About';
import Projects from './components/Projects';
import Thoughts from './components/Thoughts';
import Skills from './components/Skills';
import Contact from './components/Contact';
import SignupForm from './components/Signup';
import Dashboard from './components/Dashboard';
import ChatManager from './components/ChatManager';
import BlogManager from './components/BlogManager';
import Users from './components/Users';
import Prompts from './components/Prompts';
import Footer from './components/Footer';
import FloatingChatBox from './components/FloatingChatBox';
import PrivateRoute from './components/PrivateRoute';

import './css/App.css';
import './css/Fonts.css';

import { UserAuthenticationProvider } from './services/UserAuthenticationContext';

function App() {
    function Main(){
        return (
            <ModalProvider>
                <main>
                    <Modal />
                    <Header />
                    <MenuMain />
                    <About />
                    <Projects />
                    <Skills />
                    <Thoughts />
                    <Contact />
                    <Footer />
                </main>
                <FloatingChatBox />
            </ModalProvider>
        );
    }

    return (
        <UserAuthenticationProvider>
            <div>
               <Routes>
                    <Route path='/' element={<Main />} />
                    <Route path='/dashboard' element={<PrivateRoute><Dashboard /></PrivateRoute>} />}
                    <Route path='/chatmanager' element={<PrivateRoute><ChatManager /></PrivateRoute>} />}
                    <Route path='/blogmanager' element={<PrivateRoute><BlogManager /></PrivateRoute>} />}
                    <Route path='/prompts' element={<PrivateRoute><Prompts /></PrivateRoute>} />}
                    <Route path='/users' element={<PrivateRoute><Users /></PrivateRoute>} />}
                    <Route path='/signup' element={<SignupForm />} />
                    <Route path="*" element={<Navigate to='/' replace />} />
                </Routes>
            </div>
        </UserAuthenticationProvider>
   );
}

export default App;
