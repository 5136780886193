import React, { useState, useEffect, useContext, useCallback} from "react";

import axios from 'axios';

import { UserAuthenticationContext } from '../services/UserAuthenticationContext';

import DashboardMenu from './DashboardMenu';
import BlogForm from './BlogForm';
import Button from './Button';
import formatDate from '../services/formatDate';
import { getBlogsService } from '../services/getBlogsService';

import '../css/BlogManager.css';

import config from '../config.js';

export default function BlogManager(){
    const {
        getAuthHeader,
    } = useContext(UserAuthenticationContext);

    const [blogs, setBlogs] = useState([]);

    const getBlogs = useCallback(async () => {
        try{
            const blogs = await getBlogsService(getAuthHeader);
            
            if(blogs !== undefined){
                setBlogs(blogs);
            }
       }catch(err){
            console.log(err);
        }
    }, [getAuthHeader]);

    useEffect(() => {
        getBlogs()
    }, [getBlogs]);

    const deleteImage = async (imgName) => {
         try{
            const response = await axios.delete(`${config.API_DELETEIMAGE}${imgName}`, getAuthHeader());
            return(response);
        }catch(err){
            console.log(err);
        }
    }

    const handleDelete = async (id, imgName) => {
        try{
            const imageResponse = await deleteImage(imgName);

            if(imageResponse === undefined || imageResponse === null){
                console.log("Image did not delete");
            }else{
                const response = await axios.delete(`${config.API_DELETEBLOG}${id}`, getAuthHeader());
                console.log(response.status)
                getBlogs();
            }
       }catch(err){
            console.log(err);
        }
    }

    return (
        <div>
            <DashboardMenu />
            <BlogForm getBlogs={getBlogs} />
            {blogs.map((blogObject, blogIndex) => (
                <div
                    key={blogIndex} 
                >
                    <div
                        className='blog-title-container' 
                    >
                        {blogObject.title}
                    </div>
                    <div
                        className='blog-date-container' 
                    >
                        {formatDate(blogObject.date)}
                    </div>
                    <div
                        className='blog-image-container'
                    >
                        <img 
                            src={blogObject.imgSrc}
                            alt=""
                        />
                    </div>
                    <div
                        className='blog-body-container' 
                    >
                        <pre
                            className='blog-body' 
                        >
                            {blogObject.body}
                        </pre>
                    </div>

                    <Button
                        label={"Delete Post"} 
                        onClick={() => handleDelete(blogObject._id, blogObject.imgName)}
                        className='blog-delete-button'
                    />
                </div>
            ))}
        </div>
    );
};
