import FadeIn from "./FadeIn";

import "../css/Content.css";

export default function ContentMain({content, children}) {
    return(
        <FadeIn>
            <div className="content-main" id={content.contentId}>
                <h2>{content.intro}</h2>
                <h1>{content.title}</h1>
                <p>{content.mainText}</p>
                {children}
            </div>
        </FadeIn>
    );
};
