import React, { useState, useContext } from 'react';

import axios from 'axios';

import { UserAuthenticationContext } from '../services/UserAuthenticationContext';

import Button from './Button';

import config from '../config.js';

import '../css/Prompt.css';

export default function Prompt({promptData, fetchPrompts}){
    const {
        getAuthHeader,
    } = useContext(UserAuthenticationContext);

    const id = promptData._id;
    const promptName = promptData.promptName;
    const [promptText, setPromptText] = useState(promptData.promptText);

    const handleDeletePrompt = async () => {
        await axios.delete(`${config.API_DELTEPROMPT}${id}`, getAuthHeader());

        fetchPrompts();
    }

    const handleUpdatePrompt = async () => {
        await axios.put(`${config.API_UPDATEPROMPT}${id}`, {
            promptName: promptName,
            promptText: promptText
        }, getAuthHeader());
    }

    return(
        <div
            key={id}
            className='display-prompt-container'
        >
            <div
                className='display-prompt-name-container'
            >
                {"Prompt: " + promptName}
            </div>
            <div
                className='display-prompt-text-container'
            >
                <textarea
                    value={promptText}                            
                    onChange={(e) => setPromptText(e.target.value)}
                />
            </div>
            <div
                className='prompt-buttons-container'
            >
                <Button
                    label="Delete Prompt"
                    onClick={() => handleDeletePrompt()}
                    className='prompt-button'
                />
                <Button
                    label="Update Prompt"
                    onClick={() => handleUpdatePrompt()}
                    className='prompt-button'
                />
            </div>
        </div>
    )
}
