import React, { useEffect, useState, useContext } from 'react';

import axios from 'axios';

import { UserAuthenticationContext } from '../services/UserAuthenticationContext';

import DashboardMenu from './DashboardMenu';
import UserCell from './UserCell';

import config from '../config.js';

import '../css/Users.css';

export default function Users(){
    const {
        getAuthHeader,
        handleLogout,
    } = useContext(UserAuthenticationContext);

    const [userLogs, setUserLogs] = useState([]);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const fetchUsers = async () => {
         try{
            const response = await axios.get(config.API_GETUSERS, getAuthHeader());
            setUserLogs(response.data);
        }catch(err){
            handleLogout();
        }
    }

    useEffect(() => {
        fetchUsers();
    }, []);

    const handleCreateUser = async (event) => {
        event.preventDefault();
        setErrorMessage('');

        try{
            await axios.post(config.API_SIGNUP, { email, password, name });
            fetchUsers();
        }catch(error){
            if(error.response && error.response.data){
                setErrorMessage(error.response.data.message);
            }else{
                setErrorMessage('An unknown error occurred.');
            }
        }
    }

    return(
        <>
            <DashboardMenu />
            <table>
                <thead>
                    <tr>
                        <th>{"Name"}</th>
                        <th>{"Email"}</th>
                        <th>{"Role"}</th>
                    </tr>
                </thead>
                <tbody>
                    {userLogs.map((userData, userDataIndex) => (
                        <UserCell key={userDataIndex} userData={userData} fetchUsers={fetchUsers} />
                    ))}
                </tbody>
            </table>

            <form
                onSubmit={(event) => handleCreateUser(event)} 
            >
                <div>
                    {errorMessage}
                </div>

                <input
                    className='user-create-input'
                    type='name'
                    name='name'
                    placeholder="Name"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    required
                />

                <input
                    className='user-create-input'
                    type='email'
                    name='email'
                    placeholder="Email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    required
                />

                <input
                    className='user-create-input'
                    type='password'
                    name='password'
                    placeholder="Password"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    required
                />

                <input
                    className='user-create-button'
                    type='submit'
                    value="Create User"
                />
            </form>
        </>
    )
}
