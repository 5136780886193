import { useState, useEffect, useRef } from 'react';

import axios from 'axios';
import io from 'socket.io-client';
import parseMessageForCode from '../services/parseMessageForCode';
import { sentimentOptions } from '../data/data.js';
import hljs from 'highlight.js';

import '../css/dracula.css';
import '../css/Chat.css';

import config from '../config.js';

const socket = io(process.env.REACT_APP_SOCKET_ENDPOINT);

export default function Chat({uuid, selectedPrompt}){
    const conversationAreaRef = useRef();

    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState([]);
    const [isTyping, setIsTyping] = useState(false);

    const sentimentOptionsString = Object.keys(sentimentOptions).join(", ");

    const findChatById = async () => {
        try{
            const response = await axios.get(`${config.API_FINDCHATBYID}${uuid}`);

            setMessages(response.data);
        }catch(err){
            console.log(err);
        }
    }

    useEffect(() => {
        findChatById();
    }, [uuid]);

    const handleEnterUserSubmit = (event) => {
        event.preventDefault();

        if(event.key === "Enter" && !event.shiftKey){
            handleUserSubmit(event);
        }
    }

    const handleUserSubmit = (event) => {
        event.preventDefault();
        const messageObject = {
            message: inputValue,
            id: uuid,
            messageCount: messages.length + 1,
            sentiment: 'talking',
            sentimentOptions: sentimentOptionsString,
            userId: "user",
        };

        socket.emit('message', [messageObject, selectedPrompt]);
        setInputValue('');
    };

    useEffect(() => {
        socket.on('message', (data) => {
            setMessages((prevMessages) => [...prevMessages, data]);
            data.userId === "user" ? setIsTyping(true) : setIsTyping(false);
        });
    }, []);

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    useEffect(() => {
        document.querySelectorAll('#unhighlighted-code').forEach(element => {
            hljs.highlightElement(element);
            element.id = 'highlighted-code';
        });
        conversationAreaRef.current.scrollTop = conversationAreaRef.current.scrollHeight;
    }, [messages]);

    const IsTypingMessage = () => {
        return(
            isTyping && <div
                className='chat-message-container' 
            >
                <img 
                    src={sentimentOptions['nottalking']} 
                    alt=''
                    height={'60px'}
                />
                <div
                    className='chat-message-text-container' 
                >
                    {"Typing..."}
                </div>
            </div>
        );
    };

    return(
        <form
            className='chat-container' 
            onSubmit={handleUserSubmit}
        >
            <div
                className='chat-conversation-area'
                type='text'
                name='conversation-area'
            >
                <div
                    className='chat-conversation-area-padding'
                    ref={conversationAreaRef}
                >
                    {messages.map((messageObject, messageIndex) =>
                        <div
                            className='chat-message-container' 
                            key={messageIndex}
                        >
                            {messageObject.userId === "assistant" && <img 
                                src={sentimentOptions[messageObject.sentiment]} 
                                alt=''
                                height={'60px'}
                            />}
                            <div
                                className='chat-message-text-container' 
                                style={{
                                    color: (messageObject.userId === "assistant" ? 'black' : 'green'),
                                }}
                            >
                                {parseMessageForCode(messageObject.message)}
                           </div>
                        </div>
                    )}
                    <IsTypingMessage />
                </div>
            </div>

            <textarea
                className='chat-input-area'
                type='text'
                name='input-area'
                multiline='true'
                placeholder='Message'
                autoFocus
                value={inputValue}
                onChange={handleInputChange}
                onKeyUp={handleEnterUserSubmit} 
            />

            <input
                className='chat-conversation-button'
                type='submit'
                value="Submit"
            />
        </form>
    );
};
