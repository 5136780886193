import React from 'react';

import { Link } from 'react-router-dom';

import LogoutButton from './LogoutButton';

import '../css/DashboardMenu.css';

export default function DashboardMenu(){
    return(
        <div
            className='dashboardmenu-container' 
        >
            <LogoutButton />
            <div
                className='dashboardmenu-link' 
            >
                <Link 
                    to="/dashboard"
                    className='dashboardmenu-link-style'
                >
                    {"Dashboard"}
                </Link>
            </div>
            <div
                className='dashboardmenu-link' 
            >
                <Link
                    to="/chatManager"
                    className='dashboardmenu-link-style'
                >
                    {"Chat Manager"}
                </Link>
            </div>
            <div
                className='dashboardmenu-link' 
            >
                <Link
                    to="/blogmanager"
                    className='dashboardmenu-link-style'
                >
                    {"Blog Manager"}
                </Link>
            </div>
            <div
                className='dashboardmenu-link' 
            >
                <Link
                    to="/prompts"
                    className='dashboardmenu-link-style'
                >
                    {"Prompts"}
                </Link>
            </div>
            <div
                className='dashboardmenu-link' 
            >
                <Link
                    to="/users"
                    className='dashboardmenu-link-style'
                >
                    {"Users"}
                </Link>
            </div>
            <div
                className='dashboardmenu-link' 
            >
                <Link
                    to="/signup"
                    className='dashboardmenu-link-style'
                >
                    {"Signup"}
                </Link>
            </div>
        </div>
    )
}
