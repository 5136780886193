import ContentMain from '../components/ContentMain';

import { content } from '../data/data.js';

import TechLogos from '../components/TechLogos';

export default function Skills(){
    return(
        <ContentMain content={content.skills}>
            <TechLogos />
        </ContentMain>
    );
};
