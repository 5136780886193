function MenuItem({onClick, status, title}){
    return(
        <div className="menu-item" onClick={onClick}>
            <div className={status} />
            {title}
        </div>
    )
}

export default MenuItem;
