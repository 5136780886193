import { useRef, useEffect, useState } from "react";
import "../css/FadeIn.css";

export default function FadeIn({children}) {
    const ref = useRef();

    const [fadeInStatus, setFadeInStatus] = useState("fadeIn-inactive");

    const setStatus = () => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => setFadeInStatus(
                entry.isIntersecting ? "fadeIn-active" : "fadeIn-inactive")
            );
        }, {
            root: document.querySelector(".container"),
            rootMargin: '-30% 0% 0% 0%',    
        });

        observer.observe(ref.current);

        return() => {
            observer.unobserve(ref.current)
        };
    }

    useEffect(() => {
        setStatus();
    });

    return(
        <div
            ref={ref}
            className={fadeInStatus}
        >
            {children}
        </div>
    );
}
