import ContentMain from '../components/ContentMain';
import ContactForm from '../components/ContactForm';

import { content } from '../data/data.js';

export default function Contact(){
    return(
        <ContentMain content={content.contact}>
            <ContactForm />
        </ContentMain>
    );
};
