import { useContext } from 'react';

import "../css/Modal.css";

import { ModalContext } from '../services/ModalContext';
import formatDate from '../services/formatDate';

export default function Modal(props) {
    const {
        isActive,
        closeModal,
        content,
    } = useContext(ModalContext);

    return(
        <>
           {isActive && <div 
                className='modal-active'
                onClick={closeModal} 
            >
                {content.type === "image" && <div className='modal-image-container'>
                    <img
                        src={content.content}
                        alt=''
                    />
                </div>}
                {content.type === "blogpost" && <div className='modal-blogpost-container'>
                    <div className='modal-blogpost-inner-container'>
                        <div
                            className='modal-blogpost-title' 
                        >
                            {content.content.title}

                            <div
                                className='modal-blogpost-date-container' 
                            >
                                {formatDate(content.content.date)}
                            </div>
                        </div> 
                        <div
                            className='modal-blogpost-image-container' 
                        >
                            <img
                                src={content.content.imgSrc}
                                alt=''
                            />
                        </div>
                        <div
                            className='modal-blogpost-body-container' 
                        >
                            <pre
                                className='modal-blogpost-body'
                            >
                                {content.content.body}
                            </pre>
                        </div>
                    </div>
                </div>}
                {content.type === "alert" && <div className='modal-alert-container'>
                    <div
                        className='modal-alert-title' 
                    >
                        {content.content.title}
                    </div> 
                    <div
                        className='modal-alert-body' 
                    >
                        {content.content.body}
                    </div>
                </div>}
            </div>}
        </>
    );
};
