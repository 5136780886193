import { createContext, useState } from 'react';

export const ModalContext = createContext();

export const ModalProvider = ({children}) => {
    const [isActive, setIsActive] = useState(false);
    const [content, setContent] = useState();

    const openModal = (content) => {
        setContent(content);
        setIsActive(true); 
        document.body.style.position = 'relative';
        document.body.style.overflow = 'hidden';
    };

    const closeModal = () => {
        setContent();
        setIsActive(false); 
        document.body.style.position = '';
        document.body.style.overflow = '';
    };

    return(
        <ModalContext.Provider
            value={{
                isActive,
                openModal,
                closeModal,
                content,
            }} 
        >
           {children}
        </ModalContext.Provider>
    );
};
