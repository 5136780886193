//import DOMPurify from 'dompurify';

export default function parseMessageForCode(input) {
    if(input === undefined){
        return("");
    }

    if(input.includes("```")){
        const delimiter = "```";
        const blocks = input.split(delimiter);

        return(blocks.map((block, index) => {
            if(index % 2 === 1){
                //const sanitizedBlock = DOMPurify.sanitize(block, {ALLOWED_TAGS: []});
                //const sanitizedBlock = DOMPurify.sanitize(block);

                return(
                    <pre
                        key={index} 
                    >
                        <code
                            className='chat-code-container'
                            id='unhighlighted-code'
                        >
                            {block}
                        </code>
                    </pre>
                );
            }else{
                return(block);
            }
        }));
    }else{
        return(input);
    }
};
