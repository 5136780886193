import { useState, useEffect, useContext } from 'react';
import { Navigate } from 'react-router-dom';

import { UserAuthenticationContext } from '../services/UserAuthenticationContext';

export default function PrivateRoute({ children }){
    const { checkToken } = useContext(UserAuthenticationContext);

    const [isAuthenticated, setIsAuthenticated] = useState(null);

    useEffect(() => {
        const authenticate = async () => {
            const isAuthenticated = await checkToken();
            setIsAuthenticated(isAuthenticated);
        };

        authenticate();
    }, [checkToken]);

    if(isAuthenticated === null){
        return(<div>Loading...</div>);
    }

    return(isAuthenticated ? children : <Navigate to='/login' />);
}
