import React from 'react';

import DashboardMenu from './DashboardMenu';

import '../css/Dashboard.css';

export const Dashboard = () => {
    return (
        <div
            className='dashboard-container' 
        >
            <DashboardMenu />
        </div>
    );
};

export default Dashboard;
