import React, { useState, useEffect, useContext } from "react";

import axios from 'axios';

import { UserAuthenticationContext } from '../services/UserAuthenticationContext';

import '../css/ChatPromptDropdown.css'

import config from '../config.js';

export default function DropdownMenu({selectedPrompt, setSelectedPrompt}){
    const {
        getAuthHeader,
        handleLogout,
    } = useContext(UserAuthenticationContext);

    const [promptsArray, setPromptsArray] = useState([]);

    const fetchPrompts = async () => {
        try{
            const response = await axios.get(config.API_GETPROMPTS, getAuthHeader());

            setPromptsArray(response.data);
        }catch(err){
            handleLogout();
        }
    };

    useEffect(() => {
        fetchPrompts();
    }, []);

	const handleChange = (event) => {
		setSelectedPrompt(event.target.value);
	};

	return (
		<div>
			<select
                className='prompt-dropdown'
                value={selectedPrompt}
                onChange={handleChange}
            >
                {promptsArray.map((promptObj, promptIndex) => (
				    <option 
                        className='prompt-dropdown-option'
                        key={promptIndex}
                        value={promptObj.promptName}
                    >
                        {promptObj.promptName}
                    </option>
                ))}
			</select>
		</div>
	);
}
