import axios from 'axios';
import { useState, useContext } from 'react';

import '../css/ContactForm.css';

import { ModalContext } from '../services/ModalContext';

export default function ContactForm(){
    const { openModal } = useContext(ModalContext);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();

        axios.post('/api/send', {
            name: name,
            email: email,
            message: message
        }).then((response)=>{
            if(response.data.status === 'success'){
                openModal({
                    content: {
                        title: "Message Sent!",
                        body: "Thanks for reaching out :)",
                    },
                    type: "alert",
                })
                setName('');
                setEmail('');
                setMessage('');
            } else if(response.data.status === 'fail'){
                openModal({
                    content: {
                        title: "Message failed to send.",
                        body: "Something went wrong :(",
                    },
                    type: "alert",
                })
            }
        });
    };

    return(
        <form
            className='contact-form'
            onSubmit={(event) => handleSubmit(event)}
        >
            <input
                className='contact-form-input'
                type='text'
                name='name'
                placeholder="Name"
                value={name}
                onChange={(event) => setName(event.target.value)}
                required
            />

            <input
                className='contact-form-input'
                type='text'
                name='email'
                placeholder="Email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                required
            />

            <textarea
                className='contact-form-textarea'
                type='text'
                name='comments'
                rows='10'
                placeholder="Comments"
                value={message}
                onChange={(event) => setMessage(event.target.value)}
                required
            />

            <input
                className='contact-form-button'
                type='submit'
                value="Submit"
            />
        </form>
    );
};
