import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import config from '../config.js';

export default function SignupForm() {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleSignup = async (event) => {
        event.preventDefault();
        setErrorMessage('');

        try{
            const response = await axios.post(config.API_SIGNUP, { email, password, name });
            console.log(response.data);
            navigate('/login');
        }catch(error){
            console.error(error);

            if(error.response && error.response.data){
                setErrorMessage(error.response.data.message);
            }else{
                setErrorMessage('An unknown error occurred.');
            }
        }
    }

    return (
        <form
            onSubmit={(event) => handleSignup(event)} 
        >
            <div>
                {errorMessage}
            </div>

            <input
                type='name'
                name='name'
                placeholder="Name"
                value={name}
                onChange={(event) => setName(event.target.value)}
                required
            />

            <input
                type='email'
                name='email'
                placeholder="Email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                required
            />

            <input
                type='password'
                name='password'
                placeholder="Password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                required
            />

            <input
                type='submit'
                value="Signup"
            />
        </form>
    );
}
