import { useContext } from 'react';
import { UserAuthenticationContext } from '../services/UserAuthenticationContext';

import '../css/Login.css';

export default function LoginForm(props) {
    const {
        handleLogin,
        email,
        setEmail,
        password,
        setPassword,
        errorMessage,
    } = useContext(UserAuthenticationContext);

    return (
        <form
            className='login-form'
            onSubmit={(event) => handleLogin(event)} 
        >
            <div>
                {errorMessage}
            </div>

            <input
                type='email'
                name='email'
                placeholder="Email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                required
            />

            <input
                type='password'
                name='password'
                placeholder="Password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                required
            />

            <input
                className='login-form-button'
                type='submit'
                value="Login"
            />
        </form>
    );
}
